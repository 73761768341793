import './style.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as dat from 'lil-gui';
import { Raycaster, Texture } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { passiveSupport } from 'passive-events-support/src/utils'


let sceneReady = false
THREE.ColorManagement.enabled = false

const loadingBarElement = document.querySelector('.loading-bar');
const sideBar = document.querySelector('.side-bar');
const titleContainer = document.querySelectorAll('.title-container');

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {
        // Wait a little
        window.setTimeout(() => {
            controls.enableRotate = false;
            // Update loadingBarElement
            loadingBarElement.classList.add('ended');
            // loadingBarElement.style.transform = '';
            sideBar.style.zIndex = '9999';

            titleContainer.forEach((element) => {
                element.style.zIndex = '1';
            });
        }, 500);
        window.setTimeout(() => {
            sceneReady = true
        }, 2000)
    },
);


const gltfLoader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderConfig({ type: 'js' });
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
gltfLoader.setDRACOLoader(dracoLoader);
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);

const debugObject = {};

// Debug
// const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector('canvas');

// Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(0xf7f7f7);
scene.fog = new THREE.Fog(0xf7f7f7, -4, 10);

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
    60, //field of view. If using a small angle - long scope effect and wide-angle - fish eye effect
    sizes.width / sizes.height,
    0.1,
    1000
);
if(window.innerWidth >= 1081) {
    camera.position.set(0, 0, 2)
    scene.fog = new THREE.Fog(0xf7f7f7, -4, 10);
} else if (window.innerWidth > 810) {
    camera.position.set(0, 0, 3)
}
    else {
    camera.position.set(0, 0, 3.7)
    scene.fog = new THREE.Fog(0xf7f7f7, 3, 1);
}

scene.add(camera);


// Controls
const controls = new OrbitControls(camera, document.body)
controls.enableDamping = true
controls.enableRotate = false
// controls.rotateSpeed = 5;
controls.enableZoom = false
controls.update();

/**
 * Renderer
 */
 const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true, //remove pixelated edges
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.useLegacyLights = true;
  // renderer.outputColorSpace = THREE.sRGBEncoding; //Определяет выходную кодировку средства визуализации
  renderer.toneMapping = THREE.ACESFilmicToneMapping; // or  THREE.CineonToneMapping etc.
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFShadowMap;
  
  debugObject.clearColor = '#ffffff';
  renderer.setClearColor(debugObject.clearColor);

//Lights
const ambientLight = new THREE.AmbientLight(0xffffff, 1);
scene.add(ambientLight);

// Directional light
const directionalLight = new THREE.DirectionalLight(0xffffff, 7);
directionalLight.castShadow = true;

directionalLight.shadow.mapSize.width = 1024;
directionalLight.shadow.mapSize.height = 1024;

directionalLight.shadow.camera.near = 1;
directionalLight.shadow.camera.far = 8;

directionalLight.shadow.camera.top = 8;
directionalLight.shadow.camera.right = 8;
directionalLight.shadow.camera.bottom = -8;
directionalLight.shadow.camera.left = -8;
directionalLight.position.set(0, 0.5, 0);

directionalLight.shadow.radius = 8;
scene.add(directionalLight);

// Spot light
const spotLight = new THREE.SpotLight(0xffffff, 0.3, 10, Math.PI * 0.3);
spotLight.castShadow = true;

spotLight.shadow.mapSize.width = 1024;
spotLight.shadow.mapSize.height = 1024;

spotLight.shadow.camera.near = 1;
spotLight.shadow.camera.far = 6;

spotLight.shadow.camera.fov = 50;


spotLight.position.set(0, 2, 2);
scene.add(spotLight);
scene.add(spotLight.target);

const spotLightCameraHelper = new THREE.CameraHelper(spotLight.shadow.camera);
spotLightCameraHelper.visible = false;
scene.add(spotLightCameraHelper);

/**
 * Floor
 */

 const color = new THREE.Color(0xffffff);
 const floor = new THREE.Mesh(
   new THREE.PlaneGeometry(25, 25),
   new THREE.MeshStandardMaterial({
     color: color,
     metalness: 0,
     roughness: 0.5,
   })
 );
 floor.receiveShadow = true;
 floor.rotation.x = -Math.PI * 0.5;
 floor.position.y = -1.5;
 scene.add(floor);


 //Load environment map, needs to be done after the scene
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/1/px.webp',
    '/textures/environmentMaps/1/nx.webp',
    '/textures/environmentMaps/1/py.webp',
    '/textures/environmentMaps/1/ny.webp',
    '/textures/environmentMaps/1/pz.webp',
    '/textures/environmentMaps/1/nz.webp',
]);

//env map intensity
environmentMap.colorSpace = THREE.SRGBColorSpace;
debugObject.envMapIntensity = 1;

//controls
if(window.innerWidth >= 1081) {
controls.addEventListener("change", event => {
    if (camera.position.x > 0.1 || camera.position.x < -0.1) {
        setTimeout(() => {
            document.querySelector(".return-eye").classList.add("return-eye-visible");
        document.querySelector(".panel-2").classList.add("panel-2-invisible");
        }, 50);
        
    } else {
        setTimeout(() => {
            document.querySelector(".return-eye").classList.remove("return-eye-visible")
            document.querySelector(".panel-2").classList.remove("panel-2-invisible");
        }, 50);
      
    }
})
}

let closeButton = document.querySelectorAll('.close-btn');

//points of interest
const raycaster = new Raycaster();

if (window.innerWidth >= 1081) {
    const points = [{
            position: new THREE.Vector3(1.7, 0.4, 1.2),
            element: document.querySelector('.point-0'),
        },
        {
            position: new THREE.Vector3(-0.4, 0.8, 2.2),
            element: document.querySelector('.point-1'),
        },
        {
            position: new THREE.Vector3(-0.3, -0.3, 2.2),
            element: document.querySelector('.point-2'),
        },
        {
            position: new THREE.Vector3(0.4, -0.9, 2.2),
            element: document.querySelector('.point-3'),
        },
        {
            position: new THREE.Vector3(0.7, 0.1, 2.2),
            element: document.querySelector('.point-4'),
        },
        {
            position: new THREE.Vector3(0.5, 0.5, 2.2),
            element: document.querySelector('.point-5'),
        },
    ];

    /**
     * Animate
     */
    const tick = () => {
        for (const point of points) {
            const screenPosition = point.position.clone();
            screenPosition.project(camera);

            raycaster.setFromCamera(screenPosition, camera);
            const intersects = raycaster.intersectObjects(scene.children, true);
            if (intersects.length === 0) {
                point.element.classList.add('visible');
            } else {
                const intersectDistance = intersects[0].distance;
                const pointDistance = point.position.distanceTo(camera.position);

                if (intersectDistance < pointDistance) {
                    point.element.classList.remove('visible');
                } else {
                    point.element.classList.add('visible');
                }
            }

            const translateX = (screenPosition.x * sizes.width) / 4;
            const translateY = (-screenPosition.y * sizes.height) / 6;
            point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
        }
        // Update controls
        controls.update();

        // Render
        renderer.render(scene, camera);

        // Call tick again on the next frame
        window.requestAnimationFrame(tick);
    };
    tick();
} else if(window.innerWidth <= 1080) {
    gsap.ticker.add(render);
    function render() {
        renderer.render(scene, camera);
      // required if controls.enableDamping or controls.autoRotate are set to true
      controls.update();
      }
}

gltfLoader.load(
    '/models/glTF/EYE-MODEL-new.glb',
    (gltf) => {

        if(window.innerWidth >= 1081 ) {
            gltf.scene.position.set(0, -0.6, 0.2);
        } else if(window.innerWidth > 575) {
            gltf.scene.position.set(0, 0, 0.5);
        }
        else { 
            gltf.scene.position.set(0, 0, 0.2);
        }
        
        gltf.scene.children[0].material.roughness = 0
        gltf.scene.children[0].material.ior = 1.30
        gltf.scene.children[0].material.thickness = 0.5

        scene.add(gltf.scene);
        gltf.scene.traverse(function(node) {
            if (node.isMesh) {
                node.castShadow = true;
            }
        });
        //gsap
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.defaults({
            immediateRender: false,
            ease: 'power1.inOut',
        });
        // ScrollTrigger.normalizeScroll(true)
        // ScrollTrigger.config({ autoRefreshEvents: "DOMContentLoaded,load,visibilitychange" });


        window.addEventListener('scroll', (event) => {
            if (window.scrollY > 200) {
                // $('logo-outer').addClass('fixed-header');
                document.querySelector('.logo-outer').classList.add('fixed-logo');
            } else {
                document.querySelector('.logo-outer').classList.remove('fixed-logo');
            }
        });

        if(window.innerWidth >= 1081) {
            let tlScroll = gsap.timeline({
                duration: 1,
                scrollTrigger: {
                    trigger: 'body',
                    endTrigger: '#screen2',
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: 1,
                },
            });
            tlScroll
            .to(
                camera.position, {
                    z: 3.7,
                    x: 0,
                    y: 0,
                    duration: 1,
                },
                '<'
            )
            .to(
                gltf.scene.rotation, {
                    x: 0,
                    duration: 1,
                },
                '<'
            )
            .to(
                gltf.scene.position, {
                    y: 0,
                    duration: 1,
                },
                '<'
            )
            .to(
                gltf.scene.scale, {
                    x: 1.2,
                    y: 1.2,
                    z: 1.2,
                    duration: 1,
                },
                '<'
            )
            .to(
                scene.fog, {
                    near: 3,
                    duration: 1,
                },
                '<'
            )

            let tlScrollTwo = gsap.timeline({
                duration: 1,
                scrollTrigger: {
                    id: 'trigger1',
                    trigger: '#screen2',
                    endTrigger: '#screen3',
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: 1,
                },

                onStart: function() {
                    setTimeout(() => {
                        tlScrollTwo.invalidate(0);     
                        console.log('invalidate')                  
                    }, 500);
                },       

            });
    
            tlScrollTwo.to(
                camera.position, {
                    z: 3.7,
                    x: 0,
                    y: 0,
                    duration: 1,
                },
                '<',
            )
            
      
               
            let tlScroll2 = gsap.timeline({
                duration: 1,
                opacity: 0.2,
                scrollTrigger: {
                    trigger: '#screen3',
                    endTrigger: '#screen4',
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: 1,
                },
            });

            tlScroll2
                .to(
                    gltf.scene.rotation, {
                        x: 0.2,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.position, {
                        y: 0.7,
                        z: 1.2,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    camera.position, {
                        y: 0,
                        x: 0,
                        z: 3.7,
                        duration: 1,
                    },
                    '<'
                )
                
                .to(
                    scene.fog, {
                        near: -4,
                        duration: 1,
                    },
                    '<'
                );
                }

              
        //Slide2
        let tl2 = gsap.timeline({
            duration: 1,
            paused: true,
            immediateRender: true,
        });

    
        if (window.innerWidth <= 1080) {
            tl2.to(
                gltf.scene.rotation, {
                    y: 6.3,
                    x: 0,
                    duration: 1,
                },
                '<'
            ).to(
                scene.fog, {
                    near: -4,
                    far: 10,
                    duration: 1,
                },
                '<'
            );
        } else {
            tl2
                .to(
                    gltf.scene.position, {
                        x: -5,
                        y: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.rotation, {
                        y: 5.9,
                        x: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    camera.position, {
                        x: -7.5,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.scale, {
                        x: 1.2,
                        y: 1.2,
                        z: 1.2,
                        duration: 1,
                    },
                    '<'
                )
        }
         //Slide3
         let tl3 = gsap.timeline({
            duration: 1,
            paused: true,
            immediateRender: true,
        });

        if (window.innerWidth > 1600) {
            tl3
                .to(
                    gltf.scene.position, {
                        x: 3.7,
                        y: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.rotation, {
                        y: -6,
                        x: 0.05,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    camera.position, {
                        x: 5.5,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.scale, {
                        x: 1.2,
                        y: 1.2,
                        z: 1.2,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    floor.rotation, {
                        x: -1.25,
                        duration: 1,
                    },
                    '<'
                );
        } else if (window.innerWidth < 1599 && window.innerWidth > 1300) {
            tl3
                .to(
                    gltf.scene.position, {
                        x: 3,
                        y: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.rotation, {
                        y: -6,
                        x: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    camera.position, {
                        x: 5.5,
                        duration: 1,
                    },
                    '<'
                )
        } else if (window.innerWidth < 1299 && window.innerWidth > 1100) {
            tl3
                .to(
                    gltf.scene.position, {
                        x: 3,
                        y: 0.2,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.rotation, {
                        y: -6,
                        x: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    camera.position, {
                        x: 5.5,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.scale, {
                        x: 1.3,
                        y: 1.3,
                        z: 1.3,
                        duration: 1,
                    },
                    '<'
                )

        } else if (window.innerWidth <= 1080) {
            tl3.to(
                    gltf.scene.rotation, {
                        y: -6.3,
                        x: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    scene.fog, {
                        near: -4,
                        far: 10,
                        duration: 1,
                    },
                    '<'
                );

        } else {
            tl3
                .to(
                    gltf.scene.position, {
                        x: 3,
                        y: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.rotation, {
                        y: -7.5,
                        x: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    camera.position, {
                        x: 5.5,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.scale, {
                        x: 1,
                        y: 1,
                        z: 1,
                        duration: 1,
                    },
                    '<'
                )
        }

        //same as tl2
        //Slide5
        let tl5 = gsap.timeline({
            duration: 1,
            paused: true,
            immediateRender: true,
        });

        if (window.innerWidth <= 1080) {
            tl5.to(
                gltf.scene.rotation, {
                    y: 6.3,
                    x: 0,
                    duration: 1,
                },
                '<'
            ).to(
                scene.fog, {
                    near: -4,
                    far: 10,
                    
                },
                '<'
            );
        } else {

            tl5
                .to(
                    gltf.scene.position, {
                        x: -3,
                        y: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    gltf.scene.rotation, {
                        y: 5.9,
                        x: 0,
                        duration: 1,
                    },
                    '<'
                )
                .to(
                    camera.position, {
                        x: -5.5,
                        duration: 1,
                    },
                    '<'
                )
        }


        //onclick return eye to original position
        let returnToOriginalDesktop = gsap.timeline({
            duration: 2,
            paused: true,
            immediateRender: true,
        });

        returnToOriginalDesktop
            .to(
                camera.position, {
                    x: 0,
                    y: 0,
                    z: 3.7,
                    duration: 1,
                },
                '<'
            )

        let returnToOriginalTablet = gsap.timeline({
            duration: 2,
            paused: true,
            immediateRender: true,
        });

        returnToOriginalTablet
            .to(
                camera.position, {
                    x: 0,
                    y: 0,
                    z: 3,
                    duration: 1,
                },
                '<'
            )

    let returnToOriginalMobile = gsap.timeline({
            duration: 2,
            paused: true,
            immediateRender: true,
        });

        returnToOriginalMobile
            .to(
                camera.position, {
                    x: 0,
                    y: 0,
                    z: 3.7,
                    duration: 1,
                },
                '<'
            )

            function returntoOriginalPosition() {
                if(window.innerWidth >= 1081) {
                    returnToOriginalDesktop.play();
                    returnToOriginalDesktop.restart();
                } else if((window.innerWidth > 810)) {
                    returnToOriginalTablet.play();
                    returnToOriginalTablet.restart(); 
                } else {
                    returnToOriginalMobile.play();
                    returnToOriginalMobile.restart(); 
                }
            }

        //eye right text left
        document.querySelectorAll('.point-1, .point-4').forEach((click) => {
            click.addEventListener('pointerdown', function() {
                tl2.play();
            });
        });

        document.querySelectorAll('.point-0').forEach((click) => {
            click.addEventListener('pointerdown', function() {
                tl5.play();
            });
        });

        document
        .querySelectorAll('.point-2, .point-5, .point-3')
        .forEach((click) => {
            click.addEventListener('pointerdown', function() {
                tl3.play();
            });
        });

        document.querySelector(".return-eye").addEventListener('pointerdown', function() {
            returntoOriginalPosition();
        })

        //mobile and tablet exit button
            document.querySelector(".rotate-model").addEventListener("pointerdown", function() {
                controls.enableRotate = true;
                $('.threed-eyeball-section').addClass('threed-eyeball-section-open');
                $("body").addClass("threed-eyeball-section-open")
                $(".exit").addClass("exit-open");
            })
           
            document.querySelector(".exit").addEventListener("pointerdown", function() {
                returntoOriginalPosition();
                controls.enableRotate = false;
                $('.threed-eyeball-section').removeClass('threed-eyeball-section-open');
                $(".exit").removeClass("exit-open");
                $("body").removeClass("threed-eyeball-section-open")
            })
           
        //eye left text right


        closeButton.forEach((e) => {
            e.addEventListener('pointerdown', (pointerdown) => {
                tl3.reverse()
                tl2.reverse();
                tl5.reverse();
            });
        });

        updateAllMaterials();

        let btns = document.querySelectorAll('.point');

        btns.forEach(function(btn) {
            btn.addEventListener('pointerdown', function(p) {
                if ((btn.style.opacity = 1)) {
                    btn.style['pointer-events'] = 'auto';
                }
            });
        });

        document.body.addEventListener("touchmove", ev => {
            if (ev.touches.length > 1) {
              ev.preventDefault();
              ev.stopImmediatePropagation();
            }
          }, true);

        btns.forEach(function(i) {
            i.addEventListener('pointerdown', function(e) {
                let slides = document.querySelectorAll('.slide');

                slides.forEach(function(s) {
                    if (s.dataset.n === i.dataset.n) {
                        s.classList.add('slide-visible');
                        document.querySelector("body").classList.add("slide-open")
                        document.querySelector("body").classList.remove("screen2-active")

                        document.querySelector('.title-container').classList.add('title-container-invisible');
                        $('.panel-1, .panel-2, .panel-3, .panel-4').addClass('panel-invisible');
                        controls.enableRotate = false;
                        document.querySelector('body').style.overflow = 'hidden';
                        // document.querySelector('body').style.pointerEvents = 'none';
                        document.querySelector('.scroll-indicator').classList.add('scroll-indicator-invisible');
                        document.querySelector('.side-bar').classList.add('side-bar-invisible');
                        // controls.enableZoom = true;
                    } else {
                        s.classList.remove('slide-visible');
                    }

                    let closebtn = document.querySelectorAll('.close-btn');

                    closebtn.forEach(function(c) {

                        c.addEventListener('pointerdown', function(e) {
                            c.classList.add('close-btn-open')
                            document.querySelector('.title-container').classList.remove('title-container-invisible');
                            if(window.innerWidth >=1081) {
                                controls.enableRotate = true;
                            } else {
                                controls.enableRotate = false;
                            }
                            
                            document.querySelector('body').style.overflowX = 'hidden';
                            document.querySelector('body').style.overflowY = 'scroll';
                            // document.querySelector('body').style.pointerEvents = 'auto';
                            $('.panel-1, .panel-2, .panel-3, .panel-4').removeClass('panel-invisible');
                            $('.slide-1, .slide-2, .slide-3, .slide-4, .slide-5, .slide-6').removeClass('slide-visible');

                            document
                                .querySelector('.scroll-indicator')
                                .classList.remove('scroll-indicator-invisible');
                            document
                                .querySelector('.side-bar')
                                .classList.remove('side-bar-invisible');
                            document.querySelector("body").classList.remove("slide-open")
                            document.querySelector("body").classList.add("screen2-active")
                        });
                    });
                });
            });
        });
    }
);

// Update all materials
const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (
            child instanceof THREE.Mesh &&
            child.material instanceof THREE.MeshStandardMaterial
        ) {
            child.material.envMap = environmentMap;
            child.material.envMapIntensity = debugObject.envMapIntensity;
        }
    });
};


let buttons = Array.from(document.querySelectorAll('.pointss'));
let body = document.querySelector("body")

buttons.forEach((button) => {
    button.addEventListener("pointerdown", () => {
        button.classList.toggle("open");
        body.classList.toggle("modal-open")

        setTimeout(() => {
            if (button.classList.contains('points-0')) {
                $('.title-right, .title-left, .threed-eyeball-section, nav, .side-bar, .panel-1, .panel-2, .panel-4').toggleClass('blur-filter');
            } else if (button.classList.contains('points-1')) {
                $('.age-related-macular-degeneration').toggleClass('macular-degeneration-visible');
            } else if (button.classList.contains('points-2')) {
                $('.glaucoma').toggleClass('glaucoma-visible');
                $('.title-right, .title-left, .threed-eyeball-section, nav, .side-bar, .panel-1, .panel-2, .panel-4').toggleClass('blur-filter');
            } else if (button.classList.contains('points-3')) {
                $('.title-right, .title-left, .threed-eyeball-section, nav, .side-bar, .panel-1, .panel-2, .panel-4').toggleClass('blur-filter-2');
            } else if (button.classList.contains('points-4')) {
                $('.diabetic-retinopathy').toggleClass('diabetic-retinopathy-visible');
            }
        }, 50);
    });
});

document.addEventListener("DOMContentLoaded", function(event) {
    var scrollpos = sessionStorage.getItem('scrollpos');
    if (scrollpos) {
        window.scrollTo(0, scrollpos);
        sessionStorage.removeItem('scrollpos');
    }
});

window.addEventListener("beforeunload", function(e) {
    sessionStorage.setItem('scrollpos', window.scrollY);
});


gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);


var settingUp = true;


if(window.innerWidth >= 1081) {
var buttonClicked = false;
let triggers = ScrollTrigger.getAll();

// simplified scrolling section
let sections = document.querySelectorAll(".panel");
const navLinks = gsap.utils.toArray(".menu-items .dot");
const sidebarLinks = gsap.utils.toArray(".side-bar .dot");

let isScrolling = false;
function goToSection(section, anim, i) {
  if (!isScrolling) {
    isScrolling = true;
    gsap.to(window, {
      scrollTo: { y: section, autoKill: false },
      onComplete: () => (isScrolling = false),
      duration: 1,
    });
  }
}
sections.forEach((section, i) => {
  ScrollTrigger.create({
    trigger: section,
    toggleClass: 'panel-active',
    start: "top bottom-=1",
    end: "bottom top+=1",
    onEnter: () => {goToSection(section),
        navLinks.forEach((e) => {
            e.classList.remove("active");
          });
          navLinks[i].classList.add("active"), 
          sidebarLinks.forEach((e) => {
            e.classList.remove("active");
          });
          sidebarLinks[i].classList.add("active")

        },

    onEnterBack: () => { goToSection(section),
        navLinks.forEach((e) => {
            e.classList.remove("active");
          });
          navLinks[i].classList.add("active"),
          sidebarLinks.forEach((e) => {
            e.classList.remove("active");
          });
          sidebarLinks[i].classList.add("active");
    },
  });
});


const jumpTo = (el) => {
    triggers.forEach((ins) => ins.disable());

    gsap.to(window, {
        scrollTo: {
            y: el,
            autoKill: false,
        },
        duration: 1,
        onComplete: () => {
            triggers.forEach((ins) => ins.enable());
            buttonClicked = false;
        },
       
    });
   
};
const panel = gsap.utils.toArray('.panel');

    gsap.utils.toArray('.dot').forEach((btn, i) => {
        btn.addEventListener('mousedown', (click) => {
            let panelAnchor = btn.getAttribute('data-scrollTo');
            let panelNumber = parseInt(panelAnchor.slice(-1)) - 1;
            buttonClicked = true;
            //desktop
                goToSection(panel[panelNumber]);
            //mobile
        });
    });
    settingUp = false;

    $(window).on("resize scroll", function () {
        if ($("#screen2").isInViewport()) {
          controls.enableRotate = true;
        } 
        else {
          controls.enableRotate = false;
        }
      });

} else {
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.7,
    };
    
    function observerCallback(entries, observer) {

    }
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    let handler = (entries, opts) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > opts.thresholds[0]) {
            const classesToRemove = findClassesWithActive(document.body.classList);
            if (classesToRemove.length > 0) {
              document.body.classList.remove(classesToRemove);
            }
            document.body.classList.add(entry.target.id + "-active");
          }
        });
      };
    
      var targets = document.querySelectorAll("section");
      
      targets.forEach((el) => {
        var observer = new IntersectionObserver(handler, observerOptions);
        observer.observe(el);
      });
      
      function findClassesWithActive([...classList]) {
        return classList.filter((c) => c.includes("-active"));
      }
    
    const panels = document.querySelectorAll(".panel")
    panels.forEach((el) => observer.observe(el));

    
    gsap.utils.toArray('.dot').forEach((btn, i) => {
        btn.addEventListener('mousedown', (click) => {
            let panelAnchor = btn.getAttribute('data-scrollTo');
    
            let panelNumber = parseInt(panelAnchor.slice(-1)) - 1;
                let selectedScreen = document.querySelector(`#${panelAnchor}`)
                let screenTop = selectedScreen.offsetTop;
                let mainContainer = document.querySelector('.main');
                mainContainer.scrollTo(0, screenTop);
    
        });
    });
    settingUp = false;
    
}

//check if element is in viewport and if screen2 and screen3 are - enable Rotate 
//check if element is in view and enable/disable rotation based on that
$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
  
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
  
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };


if (window.innerWidth < 1079) {
    $('.rotate-arrows').addClass('rotate-arrows-invisible');
    $('.rotate-model').addClass('rotate-model-visible');
    $('.return-eye').addClass('return-eye-touch-screen');
    // controls.enableRotate = false;
} else {
    $('.rotate-arrows').removeClass('rotate-arrows-invisible');
    $('.rotate-model').removeClass('rotate-model-visible');
    $('.return-eye').removeClass('return-eye-touch-screen');
}


$(".info-btn-content").on("pointerdown", function() {
    $("#info-modal-container").addClass("open")
    $(".info-btn-content").addClass("info-modal-open")
    $("body").addClass("overflow-hidden");
    $("body").addClass("modal-open-info");
    $('.slide').addClass('z-index-minus')
 })


$("#info-modal-container").on("pointerdown", function() {
    $("#info-modal-container").removeClass("open")
    $("body").removeClass("overflow-hidden");
    $(".info-btn-content").removeClass("info-modal-open")
    $("body").removeClass("modal-open-info");
    $('.slide').removeClass('z-index-minus')
 })

 $(".menu").on("pointerdown", function() {
    $(".menu-container").addClass("open")
    $("body").addClass("overflow-hidden");
    $(".side-bar").addClass('z-index-minus')
    $("body").addClass("modal-open-menu");
    $('.slide').addClass('z-index-minus')
})

$(".menu-container-inner").on("pointerdown", function() {
    $(".menu-container").removeClass("open")
    $("body").removeClass("overflow-hidden");
    $(".side-bar").removeClass('z-index-minus')
    $("body").removeClass("modal-open-menu");
    $('.slide').removeClass('z-index-minus')
})

var findOpticianBtn = document.querySelector('.find-optician');
let findOpticianMenuBtn = document.querySelector('.dot5')

findOpticianBtn.addEventListener('pointerdown', function() {
    setTimeout(() => {
        // window.location.href = 'https://eye.creativepreflight.com/optician-search';
        window.open(
            "https://eye.creativepreflight.com/optician-search");
    }, 500);

});

findOpticianMenuBtn.addEventListener('pointerdown', function() {
    setTimeout(() => {
         window.open(
            "https://eye.creativepreflight.com/optician-search");
    }, 500);
});



$('.owl-carousel').owlCarousel({
    autoplay: true,
    center: true,
    loop: true,
    items: 3,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
            nav: false,
        },
        600: {
            items: 1,
            nav: false,
        },
        1081: {
            items: 3,
            nav: false,
        },
    },
});

$('.owl-dots').attr('aria-hidden', 'true');

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

function setDocHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
    };
    
    addEventListener('resize', setDocHeight)
    addEventListener('orientationchange', setDocHeight)
    


    // don't forget to add "scroll-behavior: smooth;" to the .container CSS

    const scrollContainer = document.querySelector('.main')

    scrollContainer.addEventListener('touchmove', function(event) {
        scrollContainer.scrollBy(0, event.deltaY);
        return false;
    }, {passive: true})
    scrollContainer.addEventListener('touchstart', function(event) {
        scrollContainer.scrollBy(0, event.deltaY);
        return false;
    }, {passive: true})

    $('body.slide-open').on('scroll touchmove mousewheel', function(e){
        e.preventDefault();
        e.stopPropagation();
        return false;
      })
    


    window.addEventListener('resize', () =>
    {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight
    
        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()
    
        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })
    



    

